import { getCustomerByIdAPI } from "@/api/customer"
import { getServiceByIdAPI } from "@/api/service"
import { createProposalAPI } from "@/api/proposal"
const state = {
    cusProposalInfo: {},
    index: 0,
    serviceInfo: [],
    sumTotal: 0,
}

const mutations = {
    setCusProposalInfo(state, value) {
        state.cusProposalInfo = value
    },
    clearCusProposalInfo(state) {
        state.cusProposalInfo = {}
    },
    setServiceInfo(state, value) {
        state.index = value.index
        state.sumTotal += value.service_total
        state.serviceInfo.push({ value })
    },
    setServiceInfoDelete(state, value) {
        state.index = value.index - 1
        state.sumTotal = state.sumTotal - value.service_total
        state.serviceInfo.splice(value.index - 1, 1)
    },
    clearServiceInfo(state) {
        state.index = 0
        state.serviceInfo = []
        state.sumTotal = 0
    }
}

const actions = {
    proposalAddCus({ commit }, credentials) {
        return new Promise((resolve, reject) => {
            try {
                getCustomerByIdAPI(credentials.cus_id).then((response) => {
                    credentials.cus_full_name = response.data.data.cus_full_name
                    credentials.cus_address = response.data.data.cus_address
                    credentials.cus_tel = response.data.data.cus_tel
                    credentials.cus_email = response.data.data.cus_email
                    commit('setCusProposalInfo', credentials)
                    resolve(response)
                }).catch((error) => {
                    commit('clearCusProposalInfo')
                    reject(error)
                });

            } catch (error) {
                commit('clearCusProposalInfo')
                reject(error)
            }

        })
    },
    proposalAddSv({ commit }, credentials) {
        return new Promise((resolve, reject) => {
            try {
                getServiceByIdAPI(credentials.service_id).then((response) => {
                    credentials.index = (state.index) + 1
                    credentials.service_name = response.data.data.service_name
                    credentials.service_total = (credentials.pps_amount * credentials.pps_price)
                    commit('setServiceInfo', credentials)
                    resolve(response)
                }).catch((error) => {
                    commit('clearServiceInfo')
                    reject(error)
                });
            } catch (error) {
                commit('clearServiceInfo')
                reject(error)
            }
        })
    },

    proposalRemoveSv({ commit }, credentials) {
        return new Promise((resolve, reject) => {
            try {
                if (credentials.index == state.index) {
                    commit('setServiceInfoDelete', credentials)
                    resolve()
                } else {
                    reject()
                }
            } catch (error) {
                commit('clearServiceInfo')
                reject(error)
            }
        })
    },

    proposalCreate({ commit }, credentials) {
        return new Promise((resolve, reject) => {
            try {
                const pps_project = credentials.cusServiceInfo.pps_project;
                const pps_project_name = credentials.cusServiceInfo.pps_project_name;
                const pps_price_total = credentials.sumTotal;
                const pps_curency = credentials.cusServiceInfo.pps_curency;
                const pps_vat = credentials.cusServiceInfo.pps_vat;
                const pps_remark = credentials.cusServiceInfo.pps_remark;
                const pps_by = credentials.cusServiceInfo.pps_by;
                const cus_id = credentials.cusServiceInfo.cus_id;
                const pps_signature = credentials.cusServiceInfo.pps_signature;
                const serviceList = credentials.serviceList;
                createProposalAPI({
                    pps_project,
                    pps_project_name,
                    pps_price_total,
                    pps_curency,
                    pps_vat,
                    pps_remark,
                    pps_by,
                    pps_signature,
                    cus_id,
                    serviceList
                }).then((response) => {
                    commit('clearCusProposalInfo')
                    commit('clearServiceInfo')
                    resolve(response)
                }).catch((error) => {
                    commit('clearCusProposalInfo')
                    commit('clearServiceInfo')
                    reject(error)
                });
            } catch (error) {
                commit('clearCusProposalInfo')
                commit('clearServiceInfo')
                reject(error)
            }
        })
    },
    proposalClear({ commit }) {
        commit('clearCusProposalInfo')
        commit('clearServiceInfo')
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}