import DefaultContainer from '@/layouts/TheContainer'

const routes = [{
        path: '/login',
        name: 'Login',
        component: () =>
            import ('@/views/login/Login')
    },
    {
        path: "/",
        redirect: "/dashboard",
        name: 'Home',
        component: DefaultContainer,
        children: [{
                path: "dashboard",
                name: "Dashboard",
                component: () =>
                    import ('@/views/authentication/dashboard/Dashboard'),
            },
            {
                path: 'install',
                redirect: '/install/contract',
                name: 'Install',
                component: {
                    render(c) { return c('router-view') }
                },
                children: [{
                        path: 'contract',
                        name: 'Contract',
                        component: () =>
                            import ('@/views/authentication/install/contract/Contract')
                    },
                    {
                        path: 'proposal',
                        name: 'Proposal',
                        component: () =>
                            import ('@/views/authentication/install/proposal/Proposal')
                    },
                    {
                        path: 'invoice-receipt',
                        name: 'Invoice Receipt',
                        component: () =>
                            import ('@/views/authentication/install/invoice-receipt/InvoiceReceipt')
                    }
                ]
            },
            {
                path: 'manage-appliance',
                redirect: '/manage-appliance/expend-appliance',
                name: 'After Sale Service',
                component: {
                    render(c) { return c('router-view') }
                },
                children: [{
                        path: 'expend-appliance',
                        name: 'Expend Appliance',
                        component: () =>
                            import ('@/views/authentication/manage-appliance/expend-appliance/ExpendAppliance')
                    },
                    {
                        path: 'change-appliance',
                        name: 'Change Appliance',
                        component: () =>
                            import ('@/views/authentication/manage-appliance/change-appliance/ChangeAppliance')
                    },
                    {
                        path: 'add-appliance',
                        name: 'Add Appliance',
                        component: () =>
                            import ('@/views/authentication/manage-appliance/add-appliance/AddAppliance')
                    },
                    {
                        path: 'broken-appliance',
                        name: 'Broken Appliance',
                        component: () =>
                            import ('@/views/authentication/manage-appliance/broken-appliance/BrokenAppliance')
                    }
                ]
            },
            {
                path: 'document',
                redirect: '/document/activity',
                name: 'Document',
                component: {
                    render(c) { return c('router-view') }
                },
                children: [{
                        path: 'activity',
                        name: 'Activity',
                        component: () =>
                            import ('@/views/authentication/document/activity/Activity'),
                    },
                    {
                        path: 'document-income',
                        name: 'Document Income',
                        component: () =>
                            import ('@/views/authentication/document/document-income/DocumentIncome'),
                    },
                    {
                        path: 'document-out',
                        name: 'Document Out',
                        component: () =>
                            import ('@/views/authentication/document/document-out/DocumentOut'),
                    }
                ]
            },
            {
                path: 'information',
                redirect: '/information/service',
                name: 'Information',
                component: {
                    render(c) { return c('router-view') }
                },
                children: [{
                        path: 'service',
                        name: 'Service',
                        component: () =>
                            import ('@/views/authentication/information/service/Service'),
                    },
                    {
                        path: 'appliance',
                        name: 'Appliance',
                        component: () =>
                            import ('@/views/authentication/information/appliance/Appliance'),
                    },
                    {
                        path: 'appliance-group',
                        name: 'Appliance Group',
                        component: () =>
                            import ('@/views/authentication/information/appliance-group/ApplianceGroup')
                    },
                    {
                        path: 'customer',
                        name: 'Customer',
                        component: () =>
                            import ('@/views/authentication/information/customer/Customer')
                    }
                ]
            },
            {
                path: 'report',
                redirect: '/report/service-report',
                name: 'Report G1',
                component: {
                    render(c) { return c('router-view') }
                },
                children: [{
                        path: 'service-report',
                        name: 'Service Report',
                        component: () =>
                            import ('@/views/authentication/report/service-report/ServiceReport'),
                    },
                    {
                        path: 'appliance-report',
                        name: 'Appliance Report',
                        component: () =>
                            import ('@/views/authentication/report/appliance-report/ApplianceReport'),
                    },
                    {
                        path: 'customer-report',
                        name: 'Customer Report',
                        component: () =>
                            import ('@/views/authentication/report/customer-report/CustomerReport'),
                    },
                    // {
                    //     path: 'outstanding-report',
                    //     name: 'Outstanding Report',
                    //     component: () => import('@/views/authentication/report/outstanding-report/OutstandingReport'),
                    // }
                ]
            },
            {
                path: 'setting',
                redirect: '/setting/user',
                name: 'Setting',
                component: {
                    render(c) { return c('router-view') }
                },
                children: [{
                    path: 'user',
                    name: 'user Info',
                    component: () =>
                        import ('@/views/authentication/setting/user/User')
                }]
            }
        ]
    }
]

export default routes;