import { createApplianceAddAPI } from "@/api/applianceAdd"

const state = {
    applAddList: [],
    index: 0
}

const mutations = {
    setApplAddList(state, value) {
        state.index = value.index
        state.applAddList.push({ value })
    },
    clearApplAddList(state) {
        state.applAddList = []
    }
}

const actions = {
    applianceAdd({ commit }, credentials) {
        return new Promise((resolve, reject) => {
            try {
                credentials.index = (state.index) + 1
                commit('setApplAddList', credentials)
                resolve();
            } catch (error) {
                reject(error)
            }
        })
    },

    createApplianceAdd({ commit }, credentials) {
        return new Promise((resolve, reject) => {
            try {
                createApplianceAddAPI(credentials).then((response) => {
                    commit('clearApplAddList')
                    resolve(response)
                }).catch((error) => {
                    commit('clearApplAddList')
                    reject(error)
                });
            } catch (error) {
                commit('clearApplAddList')
                reject(error)
            }
        })
    },
    clearAppliance({ commit }) {
        commit('clearApplAddList')
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
