import axios from '@/libs/api.request'

export const getApplExpendAPI = () => {
    return axios.request({
        url: 'appliance-expend',
        method: 'get'
    })
}

export const getApplExpendByIdAPI = (id) => {
    return axios.request({
        url: 'appliance-expend/' + id,
        method: 'get'
    })
}

export const createApplExpendAPI = (credentials) => {
    return axios.request({
        url: 'appliance-expend',
        data: credentials,
        method: 'post'
    })
}

export const deleteApplExpendAPI = (id) => {
    return axios.request({
        url: 'appliance-expend/' + id,
        method: 'delete'
    })
}