const getters = {
    // User Info
    getToken: state => state.account.token,
    getUserName: state => state.account.username,
    getFullName: state => state.account.fullname,
    getUserGroup: state => state.account.user_group,
    getUserId: state => state.account.user_id,

    // Add Appliance
    getApplAdd: state => state.applAdd.applAddList,
    // Expend Appliance
    getApplExpend: state => state.applExpend.applAddList,
    // contract
    getContCusServiceInfo: state => state.contract.cusServiceInfo,
    getContApplListInfo: state => state.contract.applianceInfo,
    // proposal
    getProposalCusInfo: state => state.proposal.cusProposalInfo,
    getProposalSvListInfo: state => state.proposal.serviceInfo,
    getProposalTotal: state => state.proposal.sumTotal,
    // Install service
    // getInstallID : state => state.installService.installId,
    // getOldInstallID: state => state.installService.oidInstallId,
    // getCusID : state => state.installService.cus_id,
    // getInstallList : state => state.installService.installList,
    // getInstallTotal : state => state.installService.sumTotal,
}
export default getters