import axios from '@/libs/api.request'

export const getContractAPI = () => {
    return axios.request({
        url: 'contract',
        method: 'get'
    })
}

export const getContractStatusAPI = () => {
    return axios.request({
        url: 'contract-status-zero',
        method: 'get'
    })
}

export const getContractStatusApplAPI = () => {
    return axios.request({
        url: 'contract-status-appl-zero',
        method: 'get'
    })
}

export const getContractNoTExpAPI = () => {
    return axios.request({
        url: 'contract-not-expired',
        method: 'get'
    })
}

export const getContractByIdAPI = (id) => {
    return axios.request({
        url: 'contract/' + id,
        method: 'get'
    })
}

export const createContractAPI = (credentials) => {
    return axios.request({
        url: 'contract',
        data: credentials,
        method: 'post'
    })
}

export const deleteContractAPI = (id) => {
    return axios.request({
        url: 'contract/' + id,
        method: 'delete'
    })
}

export const renewContractAPI = (credentials) => {
    return axios.request({
        url: 'contract-renew',
        data: credentials,
        method: 'post'
    })
}

export const updateContractAPI = (credentials) => {
    return axios.request({
        url: 'contract-update/' + credentials.cont_id,
        data: credentials,
        method: 'put'
    })
}