<template>
  <div>
    <CDropdown inNav class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0">
      <template #toggler>
        <CHeaderNavLink>
          <div class="c-avatar">
            <CIcon name="cil-user" class="c-avatar-img" />
            <!-- <img
            src="img/avatars/6.jpg"
            class="c-avatar-img "
            />-->
          </div>
        </CHeaderNavLink>
      </template>

      <CDropdownHeader tag="div" class="text-center" color="light">
        <strong>{{form.username}}</strong>
      </CDropdownHeader>
      <CDropdownItem @click="onChangePass">
        <CIcon name="cil-settings" />ປ່ຽນລະຫັດຜ່່ານ
      </CDropdownItem>
      <CDropdownItem @click="onLogout">
        <CIcon name="cil-lock-locked" />ອອກຈາກລະບົບ
      </CDropdownItem>
    </CDropdown>
    <!-- Modal Update Branch -->
    <CModal title="ຟອມປ່ຽນລະຫັດຜ່ານ" :show.sync="modalChangePass">
      <ValidationObserver v-slot="{ invalid }">
        <CForm ref="form" :model="form" @submit.prevent="handleSubmit">
          <CRow>
            <CCol sm="6">
              <CInput label="ຊື້ເຂົ້າລະບົບ :" prop="username" v-model="form.username" disabled />
            </CCol>
            <CCol sm="6">
              <validation-provider rules="required" v-slot="{ errors }">
                <CInput
                  class="is-invalid"
                  label="ລະຫັດຜ່ານເກົ່າ :"
                  prop="password_old"
                  v-model="form.password_old"
                  type="password"
                />
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </validation-provider>
            </CCol>
            <CCol sm="6">
              <validation-provider rules="required|password:@confirm" v-slot="{ errors }">
                <CInput
                  class="is-invalid"
                  label="ລະຫັດຜ່ານໃໝ່ :"
                  prop="password_new"
                  v-model="form.password_new"
                  type="password"
                />
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </validation-provider>
            </CCol>
            <CCol sm="6">
              <validation-provider name="confirm" rules="required" v-slot="{ errors }">
                <CInput
                  class="is-invalid"
                  label="ລະຫັດຜ່ານໃໝ່ອີກຄັ້ງ :"
                  prop="password_conf"
                  v-model="form.password_conf"
                  type="password"
                />
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </validation-provider>
            </CCol>
            <CCol sm="12">
              <CButton
                :disabled="invalid"
                type="submit"
                variant="outline"
                color="warning"
                class="float-right"
              >
                <CIcon name="cil-save" />ປ່ຽນລະຫັດຜ່ານ
              </CButton>
            </CCol>
          </CRow>
        </CForm>
      </ValidationObserver>
      <template #footer>
        <div></div>
      </template>
    </CModal>
  </div>
</template>

<script>
import { encrypt } from "@/libs/crypto";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { extend } from "vee-validate";
import { mapActions } from "vuex";
import { accountChangePasswordAPI } from "@/api/account";
import { decrypt } from "@/libs/crypto";

// check confirm password
extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "ຢືນຢັນລະຫັດຜ່ານບໍ່ຕົງກັນ",
});
export default {
  name: "TheHeaderDropdownAccnt",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      itemsCount: 42,
      modalChangePass: false,
      form: {
        username: decrypt(this.$store.getters.getUserName),
        password_old: "",
        password_new: "",
        password_conf: "",
      },
    };
  },
  methods: {
    ...mapActions("account", ["logout"]),

    // click form change password
    onChangePass() {
      this.modalChangePass = true;
    },
    // submit change password
    handleSubmit() {
      let username = this.form.username;
      let password_old = encrypt(this.form.password_old);
      let password_new = encrypt(this.form.password_new);
      accountChangePasswordAPI({
        username,
        password_old,
        password_new,
      })
        .then((response) => {
          this.$awn.success(response.data.message);
        })
        .catch((error) => {
          this.$awn.alert(error.response.data.message);
        });
      this.modalChangePass = false;
    },
    onLogout() {
      let onOk = () => {
        this.logout().then((response) => {
          this.$router.push({ name: "Login" });
        });
      };
      this.$awn.confirm("ເຈົ້າຕ້ອງການອອກຈາກລະບົບແທ້ ຫຼີ ບໍ່ ?", onOk);
    },
  },
};
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>