import axios from '@/libs/api.request'

export const getCustomerAPI = () => {
    return axios.request({
        url: 'customer',
        method: 'get'
    })
}

export const getCustomerByGroupAPI = (id) => {
    return axios.request({
        url: 'customer-by-group/' + id,
        method: 'get'
    })
}

export const getCustomerByIdDetailAPI = (id) => {
    return axios.request({
        url: 'customer-by-id-detail/' + id,
        method: 'get'
    })
}

export const getCustomerNearInvAPI = (credentials) => {
    return axios.request({
        url: 'customer-near-invoice',
        data: credentials,
        method: 'post'
    })
}

export const getCustomerOutstdAPI = () => {
    return axios.request({
        url: 'customer-outstanding',
        method: 'get'
    })
}

export const getCustomerNearCtAPI = (credentials) => {
    return axios.request({
        url: 'customer-near-contract',
        data: credentials,
        method: 'post'
    })
}

export const getCustomerNotCtAPI = () => {
    return axios.request({
        url: 'customer-not-contract',
        method: 'get'
    })
}

export const getCustomerCountInfoAPI = () => {
    return axios.request({
        url: 'customer-get-info',
        method: 'get'
    })
}

export const getCustomerWaitPayAPI = () => {
    return axios.request({
        url: 'customer-wait-payment',
        method: 'get'
    })
}

export const getCusServiceTotalAPI = () => {
    return axios.request({
        url: 'service-cus-service-report',
        method: 'get'
    })
}

export const getCusSvNearInv = () => {
    return axios.request({
        url: 'cus-near-inv',
        method: 'get'
    })
}

export const getCustomerByIdAPI = (id) => {
    return axios.request({
        url: 'customer/' + id,
        method: 'get'
    })
}

export const createCustomerAPI = (credentials) => {
    return axios.request({
        url: 'customer',
        data: credentials,
        method: 'post'
    })
}

export const updateCustomerAPI = (credentials) => {
    return axios.request({
        url: 'customer/' + credentials.id,
        data: credentials,
        method: 'put'
    })
}

export const deleteCustomerAPI = (id) => {
    return axios.request({
        url: 'customer/' + id,
        method: 'delete'
    })
}