import VueCookies from 'vue-cookies'
import { decode } from './jwtDecode'
import { encrypt } from './crypto'

export function getCUser() {
    const c_name = VueCookies.get('c_name')
    if (c_name) return c_name
    else return null
}

export function getFName(){
    const c_fullname = VueCookies.get('c_fullname')
    if (c_fullname) return c_fullname
    else return null
}

export function getCG() {
    const c_g = VueCookies.get('c_g')
    if (c_g) return c_g
    else return null
}
export function getCID() {
    const c_sid = VueCookies.get('c_id')
    if (c_sid) return c_sid
    else return null
}

export function setUserInfo(token) {
    let user = decode(token)
    VueCookies.set("c_name", encrypt(user.username));
    VueCookies.set("c_fullname", encrypt(user.user_full_name));
    VueCookies.set("c_g", encrypt(user.user_group));
    VueCookies.set("c_id", encrypt(user.user_id));
    return
}

export function removeUserInfo() {
    VueCookies.remove("c_name")
    VueCookies.remove("c_fullname")
    VueCookies.remove("c_g")
    VueCookies.remove("c_id")
    return
}