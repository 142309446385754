import axios from '@/libs/api.request'

export const accountLoginAPI = (credentials) => {
  return axios.request({
    url: 'authentication/login',
    data: credentials,
    method: 'post'
  })
}

export const accountChangePasswordAPI = (credentials) => {
  return axios.request({
    url: 'authentication/change-password',
    data: credentials,
    method: 'put'
  })
}

