import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import VueCookies from 'vue-cookies'
import VueAWN from "vue-awesome-notifications"
import { ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import vSelect from 'vue-select'
import config from '@/config'
import datePicker from 'vue-bootstrap-datetimepicker'

import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';

import VueHtmlToPaper from 'vue-html-to-paper';

// css
import "@/assets/css/styles.css"

Vue.config.performance = true

Vue.use(CoreuiVue)

// use VueCookies
Vue.use(VueCookies)

Vue.use(datePicker)

// set default config
Vue.$cookies.config(config.cookieExpires)

// set global cookie
Vue.$cookies.set('theme', 'default');
Vue.$cookies.set('hover-time', '1s');

// config message vee validate
extend('required', {
    ...required,
    message: 'ກະລຸນາປ້ອນຂໍ້ມູນ'
});

// option for vue html to paper
const options = {
    styles: [
        'http://103.151.77.5/bootstrap/4.0.0/css/bootstrap.min.css',
        'http://103.151.77.5/bootstrap/4.0.0/css/styles.css'
    ]
}

// config for vue html to paper
Vue.use(VueHtmlToPaper, options);

Vue.use(VueAWN, {
    labels: {
        info: "ແຈ້ງເຕືອນ",
        success: "ແຈ້ງເຕືອນ",
        warning: "ແຈ້ງເຕືອນ",
        alert: "ແຈ້ງເຕືອນ",
        confirm: "",
        confirmOk: "ຕົກລົງ",
        confirmCancel: "ຍົກເລີກ"
    }
})

Vue.component('v-select', vSelect)

Vue.filter('numFormat', numFormat(numeral));

Vue.prototype.$log = console.log.bind(console)

new Vue({
    el: '#app',
    router,
    store,
    icons,
    template: '<App/>',
    components: {
        App,
        ValidationProvider
    }
})