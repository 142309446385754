import Vue from "vue"
import VueRouter from "vue-router"
import routes from "./routes"
import store from '../store'

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'hash',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes, // short for routes: routes
});

router.beforeEach((to, from, next) => {
  const Auth = store.getters.getToken

  if (!Auth && to.name !== 'Login') {
    next({
      name: 'Login'
    })
  } else if (!Auth && to.name === 'Login') {
    next()
  } else if (Auth) {
    next()
  }
})

export default router;
