import axios from '@/libs/api.request'

export const getProposalAPI = () => {
    return axios.request({
        url: 'proposal',
        method: 'get'
    })
}

export const getProposalStatusAPI = () => {
    return axios.request({
        url: 'proposal-status-zero',
        method: 'get'
    })
}

export const getProposalStatusApplAPI = () => {
    return axios.request({
        url: 'proposal-status-appl-zero',
        method: 'get'
    })
}

export const getProposalByIdAPI = (id) => {
    return axios.request({
        url: 'proposal/' + id,
        method: 'get'
    })
}

export const createProposalAPI = (credentials) => {
    return axios.request({
        url: 'proposal',
        data: credentials,
        method: 'post'
    })
}

export const updateProposalAPI = (credentials) => {
    return axios.request({
        url: 'proposal/' + credentials.id,
        data: credentials,
        method: 'put'
    })
}

export const deleteProposalAP = (id) => {
    return axios.request({
        url: 'proposal/' + id,
        method: 'delete'
    })
}