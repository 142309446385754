import axios from 'axios'
import store from '@/store'
import { getToken } from '@/libs/auth'
// import VueAWN from "vue-awesome-notifications"

class HttpRequest {

    constructor(baseUrl = baseURL) {
        this.baseUrl = baseUrl
        this.queue = {}
    }
    getInsideConfig() {
        const config = {
            baseURL: this.baseUrl,
            headers: {
                Authorization: getToken()
            }
        }
        return config
    }
    destroy(url) {
        delete this.queue[url]
        if (!Object.keys(this.queue).length) {
            // Spin.hide()
        }
    }
    interceptors(instance, url) {
        instance.interceptors.request.use(config => {
            if (!Object.keys(this.queue).length) {}
            this.queue[url] = true
            return config
        }, error => {
            return Promise.reject(error)
        })

        instance.interceptors.response.use(
            response => {
                this.destroy(url)
                const { data, status } = response
                return { data, status }

            },
            error => {
                this.destroy(url)
                if (error.response.data.code === 401) {
                    // VueAWN.warning("Token Expired")
                    store.dispatch('account/logout').then(() => {
                        location.reload()
                    })

                }
                // addErrorLog(errorInfo)
                return Promise.reject(error)
            })
    }
    request(options) {
        const instance = axios.create()
        options = Object.assign(this.getInsideConfig(), options)
        this.interceptors(instance, options.url)
        return instance(options)
    }
}
export default HttpRequest