export default [{
    _name: 'CSidebarNav',
    _children: [{
            _name: 'CSidebarNavItem',
            name: 'ກະດານຂ່າວ',
            to: '/dashboard',
            icon: 'cil-speedometer',
        },
        {
            _name: 'CSidebarNavTitle',
            _children: ['ບໍລິການ']
        },
        {
            _name: 'CSidebarNavDropdown',
            name: 'ການຕິດຕັ້ງ',
            route: '/install',
            icon: 'cil-cast',
            items: [{
                    name: 'ສັນຍາບໍລິການ',
                    to: '/install/contract',
                },
                {
                    name: 'ສະເໜີລາຄາ',
                    to: '/install/proposal',
                },
                {
                    name: 'ໃບແຈ້ງ-ຮັບເງິນ',
                    to: '/install/invoice-receipt',
                }
            ]
        },
        {
            _name: 'CSidebarNavTitle',
            _children: ['ຈັດການ']
        },
        {
            _name: 'CSidebarNavDropdown',
            name: 'ຈັດການອຸປະກອນ',
            route: '/after-sale-service',
            icon: 'cil-devices',
            items: [{
                    name: 'ເບິກອຸປະກອນຕິດຕັ້ງ',
                    to: '/manage-appliance/expend-appliance',
                },
                {
                    name: 'ປ່ຽນອຸປະກອນ',
                    to: '/manage-appliance/change-appliance',
                },
                {
                    name: 'ຈັດອຸປະກອນເຂົ້າສາງ',
                    to: '/manage-appliance/add-appliance',
                },
                {
                    name: 'ອຸປະກອນເພ',
                    to: '/manage-appliance/broken-appliance',
                }
            ]
        },
        {
            _name: 'CSidebarNavDropdown',
            name: 'ເອກະສານ',
            route: '/document',
            icon: 'cil-newspaper',
            items: [{
                    name: 'ແຜນງານ',
                    to: '/document/activity'
                },
                {
                    name: 'ເອກະສານຂາອອກ',
                    to: '/document/document-out'
                },
                {
                    name: 'ເອກະສານຂາເຂົ້າ',
                    to: '/document/document-income'
                },
            ]
        },
        {
            _name: 'CSidebarNavDropdown',
            name: 'ຂໍ້ມູນ',
            route: '/information',
            icon: 'cil-info',
            items: [{
                    name: 'ຂໍ້ມູນຜະລິດຕະພັນ-ບໍລິການ',
                    to: '/information/service'
                },
                {
                    name: 'ຂໍ້ມູນອຸປະກອນ',
                    to: '/information/appliance'
                },
                {
                    name: 'ຂໍ້ມູນປະເພດອຸປະກອນ',
                    to: '/information/appliance-group',
                },
                {
                    name: 'ຂໍ້ມູນລູກຄ້າ',
                    to: '/information/customer'
                }
            ]
        },
        {
            _name: 'CSidebarNavTitle',
            _children: ['ລາຍງານ']
        },
        {
            _name: 'CSidebarNavDropdown',
            name: 'ລາຍງານ',
            route: '/report',
            icon: 'cil-print',
            items: [{
                    name: 'ລາຍງານການບໍລິການ',
                    to: '/report/service-report'
                },
                {
                    name: 'ລາຍງານອຸປະກອນ',
                    to: '/report/appliance-report'
                },
                {
                    name: 'ລາຍງານຂໍ້ມູນລູກຄ້າ',
                    to: '/report/customer-report'
                }
            ]
        },
        {
            _name: 'CSidebarNavTitle',
            _children: ['ຕັ້ງຄ່າ']
        },
        {
            _name: 'CSidebarNavDropdown',
            name: 'ຕັ້ງຄ່າລະບົບ',
            route: '/setting',
            icon: 'cil-people',
            items: [{
                name: 'ຜູ້ໃຊ້ງານລະບົບ',
                to: '/setting/user'
            }]
        }
    ]
}]