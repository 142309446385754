import config from '@/config'
import crypto from "crypto-js";

// encrypt password
export const encrypt = (text) => {
    var encrypted = crypto.AES.encrypt(text, config.encryptKeyword);
    return encrypted.toString();
  }

  // decrypt password
export const decrypt = (text) => {
  var decrypted = crypto.AES.decrypt(text, config.encryptKeyword);
  return decrypted.toString(crypto.enc.Utf8);
}