import axios from '@/libs/api.request'

export const getApplianceAddAPI = () => {
  return axios.request({
    url: 'appliance-add',
    method: 'get'
  })
}

export const getApplianceAddByIdAPI = (id) => {
  return axios.request({
    url: 'appliance-add/' + id,
    method: 'get'
  })
}

export const createApplianceAddAPI = (credentials) => {
  return axios.request({
    url: 'appliance-add',
    data: credentials,
    method: 'post'
  })
}

export const deleteApplianceAddAPI = (id) => {
  return axios.request({
    url: 'appliance-add/' + id,
    method: 'delete'
  })
}