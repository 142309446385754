import axios from '@/libs/api.request'

export const getApplianceAPI = () => {
    return axios.request({
        url: 'appliance',
        method: 'get'
    })
}

export const getApplianceByIdAPI = (id) => {
    return axios.request({
        url: 'appliance/' + id,
        method: 'get'
    })
}

export const getApplianceByIdDetailAPI = (id) => {
    return axios.request({
        url: 'appliance-detail/' + id,
        method: 'get'
    })
}

export const getApplianceByGroupAPI = (id) => {
    return axios.request({
        url: 'appliance-by-group/' + id,
        method: 'get'
    })
}


export const getApplianceBySerialAPI = (id) => {
    return axios.request({
        url: 'appliance-serial/' + id,
        method: 'get'
    })
}

export const getApplianceByIdSerial = (serial) => {
    return axios.request({
        url: 'appliance-serial/' + serial,
        method: 'get'
    })
}

export const createApplianceAPI = (credentials) => {
    return axios.request({
        url: 'appliance',
        data: credentials,
        method: 'post'
    })
}

export const updateApplianceAPI = (credentials) => {
    return axios.request({
        url: 'appliance/' + credentials.id,
        data: credentials,
        method: 'put'
    })
}

export const deleteApplianceAPI = (id) => {
    return axios.request({
        url: 'appliance/' + id,
        method: 'delete'
    })
}

export const uploadApplianceAPI = (credentials) => {
    return axios.request({
        url: 'appliance-upload-picture/' + credentials.id,
        data: credentials,
        method: 'put'
    })
}