import axios from '@/libs/api.request'

export const getServiceAPI = () => {
    return axios.request({
        url: 'service',
        method: 'get'
    })
}

export const getServiceByIdAPI = (id) => {
    return axios.request({
        url: 'service/' + id,
        method: 'get'
    })
}

export const getServiceByMonthAPI = (id) => {
    return axios.request({
        url: 'service-report/' + id,
        method: 'get'
    })
}

export const getServiceCusAPI = () => {
    return axios.request({
        url: 'service-cus-report',
        method: 'get'
    })
}

export const createServiceAPI = (credentials) => {
    return axios.request({
        url: 'service',
        data: credentials,
        method: 'post'
    })
}

export const updateServiceAPI = (credentials) => {
    return axios.request({
        url: 'service/' + credentials.id,
        data: credentials,
        method: 'put'
    })
}

export const deleteServiceAPI = (id) => {
    return axios.request({
        url: 'service/' + id,
        method: 'delete'
    })
}