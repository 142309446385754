import { getCustomerByIdAPI } from "@/api/customer"
import { getServiceByIdAPI } from "@/api/service"
import { createContractAPI } from "@/api/contract"
const state = {
    cusServiceInfo: {},
    index: 0,
    applianceInfo: [],
}

const mutations = {
    setCusServiceInfo(state, value) {
        state.cusServiceInfo = value
    },
    clearCusServiceInfo(state) {
        state.cusServiceInfo = {}
    },
    setApplianceInfo(state, value) {
        state.index = value.index
        state.applianceInfo.push({ value })
    },
    clearApplianceInfo(state) {
        state.index = 0
        state.applianceInfo = []
    }
}

const actions = {
    contractAddCus({ commit }, credentials) {
        return new Promise((resolve, reject) => {
            try {
                getCustomerByIdAPI(credentials.cus_id).then((response) => {
                    credentials.cus_full_name = response.data.data.cus_full_name
                    credentials.cus_address = response.data.data.cus_address
                    credentials.cus_tel = response.data.data.cus_tel
                    credentials.cus_email = response.data.data.cus_email

                    getServiceByIdAPI(credentials.service_id).then((response) => {
                        credentials.service_name = response.data.data.service_name
                        credentials.service_unit = response.data.data.service_unit
                        credentials.service_price = response.data.data.service_price
                        commit('setCusServiceInfo', credentials)
                        resolve()
                    }).catch((error) => {
                        commit('clearCusServiceInfo')
                        reject(error)
                    });
                }).catch((error) => {
                    commit('clearCusServiceInfo')
                    reject(error)
                });

            } catch (error) {
                commit('clearCusServiceInfo')
                reject(error)
            }

        })
    },
    contractAddAppl({ commit }, credentials) {
        return new Promise((resolve, reject) => {
            try {
                credentials.index = (state.index) + 1
                commit('setApplianceInfo', credentials)
                resolve()
            } catch (error) {
                commit('clearApplianceInfo')
                reject(error)
            }
        })
    },

    contractCreate({ commit }, credentials) {
        return new Promise((resolve, reject) => {
            try {
                const con_sv_remark = credentials.cusServiceInfo.con_sv_remark;
                const cont_by = credentials.cusServiceInfo.cont_by;
                const cus_id = credentials.cusServiceInfo.cus_id;
                const service_id = credentials.cusServiceInfo.service_id;
                const service_price = credentials.cusServiceInfo.service_price;
                const applList = credentials.applList;
                createContractAPI({
                    con_sv_remark,
                    cont_by,
                    cus_id,
                    service_id,
                    service_price,
                    applList
                }).then((response) => {
                    commit('clearCusServiceInfo')
                    commit('clearApplianceInfo')
                    resolve(response)
                }).catch((error) => {
                    commit('clearCusServiceInfo')
                    commit('clearApplianceInfo')
                    reject(error)
                });
            } catch (error) {
                commit('clearCusServiceInfo')
                commit('clearApplianceInfo')
                reject(error)
            }
        })
    },
    contractClear({ commit }) {
        commit('clearCusServiceInfo')
        commit('clearApplianceInfo')
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
