import VueCookies from 'vue-cookies'

const TokenKey = 'Admin-Token'

export function getToken() {
  const token = VueCookies.get(TokenKey)
  if (token) return token
  else return null
}

export function setToken(token) {
  return VueCookies.set(TokenKey, token);
}

export function removeToken() {
  return VueCookies.remove(TokenKey)
}