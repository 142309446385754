import { accountLoginAPI } from '@/api/account'
import { getToken, setToken, removeToken } from '@/libs/auth'
import { getCUser, getFName, getCG, getCID, setUserInfo, removeUserInfo } from '@/libs/userInfo'
import { encrypt } from '@/libs/crypto'
import { decode } from '@/libs/jwtDecode'

const state = {
    user_id: getCID(),
    username: getCUser(),
    fullname: getFName(),
    user_group: getCG(),
    token: getToken()
}

const mutations = {
    setUserToken(state, token) {
        state.user_id = encrypt(decode(token).user_id)
        state.username = encrypt(decode(token).username)
        state.fullname = encrypt(decode(token).user_full_name)
        state.user_group = encrypt(decode(token).user_group)
        state.token = token
    },
    clearUserToken(state) {
        state.user_id = null
        state.username = null
        state.fullname = null
        state.user_group = null
        state.token = null
    }
}

const actions = {
    login({ commit }, credentials) {
        return new Promise((resolve, reject) => {
            try {
                accountLoginAPI(credentials)
                    .then(response => {
                        const data = response.data
                        commit('setUserToken', data.token)
                        setToken(data.token)
                        setUserInfo(data.token)
                        resolve()
                    }).catch(error => {
                        reject(error)
                    })
            } catch (error) {
                reject(error)
            }

        })
    },

    logout({ commit }) {
        return new Promise((resolve, reject) => {
            try {
                commit('clearUserToken')
                removeToken()
                removeUserInfo()
                resolve()
            } catch (error) {
                commit('clearUserToken')
                removeToken()
                removeUserInfo()
                reject(error)
            }
        })
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}